import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Box from "@sholdi/primitives/atoms/Box";

export const Backdrop = ({ className, ...props }) => (
  <div
    className={twMerge(
      "flex items-center justify-center fixed top-0 left-0 bg-[rgba(0,0,0,0.5)] z-[199] w-screen h-screen",
      className,
    )}
    {...props}
  />
);

Backdrop.propTypes = {
  className: PropTypes.string,
};

export const ModalBox = forwardRef(({ className, ...props }, ref) => (
  <Box
    ref={ref}
    className={twMerge(
      "flex flex-col relative bg-white rounded-md z-40",
      className,
    )}
    {...props}
  />
));

ModalBox.displayName = "ModalBox"; // Add display name

ModalBox.propTypes = {
  className: PropTypes.string,
};

export const ModalEnding = ({ className, ...props }) => (
  <div
    className={twMerge("flex-0 py-3 w-full border border-gray-50", className)}
    {...props}
  />
);

ModalEnding.propTypes = {
  className: PropTypes.string,
};
