import { memo, useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import { Backdrop } from "../../molecules/Modal/styles";
const CartDrawer = dynamic(() => import("./CartDrawer"), { ssr: false });
const Cart = dynamic(() => import("../../organisms/MainNavbar/Cart"), {
  ssr: false,
});

const AsideShoppingCart = (props) => {
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);

  return (
    <>
      <Cart onClick={() => setIsShoppingCartOpen(true)} />
      {isShoppingCartOpen && (
        <Backdrop className="items-end justify-end">
          <CartDrawer
            isShoppingCartOpen={isShoppingCartOpen}
            setIsShoppingCartOpen={setIsShoppingCartOpen}
            {...props}
          />
        </Backdrop>
      )}
    </>
  );
};

AsideShoppingCart.displayName = "AsideShoppingCart";

AsideShoppingCart.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(AsideShoppingCart);
